*{
    font-family: 'Roboto', sans-serif;
    padding: 0px;
    margin: 0px;
}
button{
    font-weight: b;
}
.bg-footer-1{
    background: #092327;
}

.bg-1 {
    background-color: #F3EFE0;
} 
.bg-2 {
    background: #092327;
    
}
.text-color-1 {
    color: #0CA9B2;
}
.text-color-2 {
    color: #b3b3b3;
}
.primary-title-text {
    font-size: 5rem;
    line-height: 100px;
    text-transform: capitalize;


}
.primary-title-text-2 {
    font-size: 60px;
    line-height: 70px;
    text-transform: capitalize;


}
.navabr-style{
    width: 100%;
   
}
.img-info-product{
    width: 80%;
}
.title-text {
    font-size: 40px;
    word-spacing: 2px;
    text-transform: capitalize;
    line-height: 40px;
    font-weight: bold;

}
.section-bg-img{
    height: 40rem;
    width: 100%;
    position: relative;
}
.text-justify{
    text-align: justify;
}
.title-text-3{
    font-size: 20px ;
    text-transform: capitalize;
    line-height: 30px;
    font-weight: bold;

}
.sub-title-text{
    font-size: 30px;
    word-spacing: 2px;
    text-transform: capitalize;
    line-height: 40px;
}
.img-size-3{
    width: 10rem;
}
.title-text-2{
    font-size: 30px;
    text-transform: capitalize;
    line-height: 35px;
    font-weight: bold;
}
.bg-footer-1{
 background: #092327;   
}

.bg-light-left{
    background: linear-gradient(to left, rgba(0.0, 0.0, 0.0, 0.9), transparent);
}
.line-card-1-section{
    background-color: #F3EFE0;
    height: 80%;
    opacity: 0.8;
    width: 100%;
}
.section-1 {
    height:auto;
    padding-top: 5rem;
    justify-content: center;
    align-items: center;
    padding-bottom: 10rem;
    position: relative;
}
.front-index{
    z-index: 10;
}
.section-1_2 {
    height: auto;
    padding-top: 5rem;
padding-bottom: 5rem;
    
    position: relative;
}
.bg-img-1{
    width: 100%;
    z-index: 1;
    position: absolute;
    height: 100%;
}
.padding-content-footer{
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 5rem ;
    padding-bottom: 5rem ;
}
.tagline-logo-footer{
    position: absolute;
    font-size: 14px;
    margin-top: 65px;
    font-style: italic;
    margin-left: 25px;
}
.font-italic{
    font-style: italic;
}
.img-home-1{
    z-index: 1;
    position: absolute;
    right: 0px;
    width: 50%;
}
.img-section-primary-left{
    z-index: 1;
    position: absolute;
    left: 0px;
    top: 0;
    height: 100%;
    width: 50%;
}
.img-section-primary-left-only{
    z-index: 1;
    position: absolute;
    left: 0px;
}
.img-section-primary-right{
    z-index: 1;
    position: absolute;
    right: 0px;
    top: 0;
    height: 100%;
    width: 50%;
}
.title-sub-footer{
    color: #0CA9B2;
   font-weight: bold;
}
.sub-menu-footer{
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 10px;
}

.sub-menu-footer li:not(:first-child){
    margin-top: 2px;
}
.sub-menu-footer li:first-child{
    color: #0CA9B2;
    margin-top: 20px;
    text-decoration: underline;
}

.sub-menu-footer-contact{
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 10px;
}

.sub-menu-footer-contact li:first-child {
    margin-top: 20px;
}
.sub-menu-footer-contact li:not(:first-child){
    margin-top: 15px;
}
.sub-menu-footer-contact li .content {
    font-size: 14px;
    margin-left: 10px;
}
.sub-menu-footer-contact li .icon {
    font-size: 18px;
}


.sub-menu-footer-contact .sub-menu {
    margin-left: 20px; 
}
.img-section-2{
    position: absolute;
    z-index:1;
    top: 0;
    left: 0px;
    width: 50%;
    height: 100%;
}
.content-section-2{
 z-index: 10;
}

.item-container{
    padding-top: 20px;
    width: 100%;
    overflow:hidden;
    padding-bottom: 20px;

}
.rolling-costumer{
    display:flex;
    height: auto;
    animation: rollToLeft 10s linear infinite;
    justify-content: center;
}
.img-rolling-costumer{
    height: 5rem;
    transition: transform 0.1s; 
    
 
}
.content-section-1{
    margin-left: 30px;
}


.card-container{
    position: relative;
    height: 30rem;
    width: 20rem;
}
.text-color-3{
    color: #000000cf;
}

.card-content{
    position: absolute;
    width: 100%;
    z-index: 10;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: #0923275a;
    color: white;

}

.img-card{
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 100%;



}



@keyframes rollToLeft {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%); /* Bergeser ke luar layar sebelah kiri */
    }
  }
  

@media screen and (max-width : 750px) {
    .img-section-primary-left{
        height: auto;
        width: 10rem;
        top: 7rem;
    }
    .img-section-primary-right{
        height: auto;
        width: 10rem;
        top: 7rem;
    }
    .img-size-3{
       width: 80%;
    }
  
    .section-bg-img{
        height: 20rem;
    }
    
    .img-rolling-costumer{
        min-width: 0rem;
        
    }
    .content-section-1{
        margin-left: 0px;
    }
   
    
    .mobile-d-none{
        display: none;
    }
    .img-section-2{
        
        min-height: 60vh;
    }
    .section-1{
        min-height: 60vh;
        padding-top: 2rem;
        padding-bottom: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .bg-img-1{
        width: 100%;
        z-index: 1;
       height: 100%;
    }
    .title-text-2{
        font-size: 23px;
    }
    .title-text{
        font-size: 25px;
    }
    .primary-title-text-2{
        font-size: 35px;
    }
  

    .content-text{
        font-size: 14px;
    }
    .content-text-2{
        font-size:16px;
    }
    .primary-title-text{
        font-size: 45px;
        line-height: 50px;
    }
    .img-home-1{
        display: none;
        
    }
    .item-footer{
        margin-top: 30px ;
        display: flex;
        flex-direction: column;
      /*   align-items: center;
        text-align: center; */
    }
    .sub-menu-footer-contact{
        display: flex;
        flex-direction: column;
     /*    align-items: center; */
    }
    .sub-menu-footer-contact li:not(:first-child) {
        margin-top: 5px;
    }
  
    .card-logo-footer img{
        margin: auto;
    }
    .tagline-logo-footer{
        margin-left:0 ;
        text-align: center;
        margin-top: 75px;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .sub-menu-footer li:first-child{
        margin-top: 0px;
    }
    .sub-menu-footer-contact li:first-child {
        margin-top: 0px;
    }
    .rolling-costumer{
        height: 10vh;
    }
    
}
